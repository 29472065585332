<template>
  <div class="transparent-container panel-container">
    <div class="d-flex align-items-center head">
      <h2 class="filled-container__title bold">
        {{ $te(cTitle) ? $t(cTitle) : cTitle }}
      </h2>
      <div class="toolbar ml-auto">
        <slot name="toolbar" />
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: "transparent-container",
  computed: {
    cTitle() {
      return this.$route.meta.title;
    }
  }
};
</script>

<style scoped lang="scss">
.transparent-container {
  height: 100%;
  &__title {
    color: var(--th-blue-dark);
  }
  .head {
    margin-bottom: 35px;
  }
}
</style>
